import {createContext, useEffect, useState} from "react";
import {useTranslation} from "react-i18next";

const ConfigContext = createContext();

export default ConfigContext;

export const ConfigProvider = ({ children }) => {
    const language = localStorage.getItem('lang') || navigator.language || 'en'
    const [appLanguage, setAppLanguage] = useState(language)
    const {i18n} = useTranslation()

    useEffect(() => {
        i18n.changeLanguage(appLanguage)
        localStorage.setItem('lang', appLanguage)
    }, [appLanguage])

    const contextData = {
        baseUrl: process.env.REACT_APP_BASE_URL,
        language: appLanguage,
        setAppLanguage: setAppLanguage
    }

    return <ConfigContext.Provider value={contextData}>{children}</ConfigContext.Provider>
}